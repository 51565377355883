import React, {
  type Dispatch,
  type SetStateAction,
  useRef,
  useState,
  useCallback,
} from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  Divider,
  FormControlLabel,
  Switch,
} from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { Add, ExpandMore, RemoveCircle } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { FHAutoComplete } from "./FullHeightAutoComplete"
import { colors } from "../../../../../../utils"
import { CustomTextField } from "./styled"
import { last } from "lodash"
import { Fields } from "./Fields"

interface DummyDataTableProps {
  templateFields?: Record<string, any>
  model: Record<string, any>
  fieldMap: Record<string, any>
  showPreview: boolean
  setShowPreview: Dispatch<SetStateAction<boolean>>
  handleChange: (newFieldMap: Record<string, any>) => void
}

export const DummyDataView: React.FC<DummyDataTableProps> = ({
  model,
  fieldMap,
  // showPreview,
  // setShowPreview,
  handleChange,
  templateFields,
}: DummyDataTableProps) => {
  const { control, handleSubmit, setValue, getValues, unregister } = useForm({
    defaultValues: {
      ...Object.keys(fieldMap).reduce((acc: any, key) => {
        acc[key] = { type: "text" }
        return acc
      }, {}),
    },
  })

  const { t } = useTranslation()

  const [activeTab, setActiveTab] = useState<"general" | "logic">("general")

  const con = Object.keys(templateFields!).filter((key) => {
    return (
      typeof templateFields![key] !== "string" &&
      Object.keys(templateFields![key]).some((x) => x.length === 1)
    )
  })
  const [initialConditions] = con.map((key) => {
    const iC = Object.keys(templateFields![key]).filter((x) => x.length === 1)

    return {
      [key]: iC.map((x) => Number(x)),
    }
  })

  const [conditions, setConditions] =
    useState<Record<string, number[]>>(initialConditions)
  const [focusedInput, setFocusedInput] = useState<string>()
  const [currentExpanded, setCurrentExpanded] = useState<string | undefined>()

  const keys = Object.keys(model)
  const scrollToRef = useRef<any>([])

  const executeScroll = (i: number) => {
    scrollToRef?.current?.[i]?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    })
  }

  const [cursorPositions, setCursorPositions] = useState<
    Record<string, number>
  >({})

  const handleCursorChange = (fieldKey: string, position: number | null) => {
    setCursorPositions((prev) => ({
      ...prev,
      [fieldKey]: position ?? 0,
    }))
  }

  const handleKeyClick = (key: string, cursorPosition: number) => {
    if (focusedInput) {
      const prevControlValue = getValues(focusedInput) || ""

      const beforeCursor = prevControlValue.slice(0, cursorPosition)
      const afterCursor = prevControlValue.slice(cursorPosition)

      const newValue = `${beforeCursor}<${key}>${afterCursor}`

      setValue(focusedInput, newValue)
    }
  }

  const initialFieldMap =
    Object.values(fieldMap).every((el) => el === "") &&
    Object.keys(templateFields!).length > 0
      ? templateFields
      : fieldMap

  const onApplyConfigurations = (data: any, controlKey: string) => {
    const newFieldMap = {
      ...initialFieldMap,
      [controlKey]: data,
    }
    handleChange(newFieldMap)
    setCurrentExpanded("")
  }

  const updatesExist: (key: string) => boolean = (key) => {
    if (Object.keys(initialFieldMap!).length > 0) {
      return !(typeof initialFieldMap![key] === "string")
    }
    return !(typeof fieldMap[key] === "string")
  }

  const handleTabChange = useCallback(
    (_event: React.SyntheticEvent, newValue: "general" | "logic") => {
      setActiveTab((prev) => (prev === "logic" ? "general" : "logic"))
    },
    [],
  )

  const renderChildren = (type: string, key: string, index: number) => {
    switch (type) {
      case "general":
        return (
          <>
            <Fields
              model={model}
              activeTab={activeTab}
              keys={keys}
              handleClick={(selField) =>
                handleKeyClick(selField, cursorPositions[`${key}.freeText`])
              }
            />
            <Box
              sx={{
                padding: 1,
              }}
            >
              <Controller
                control={control}
                name={`${key}.freeText`}
                key={`${key}.freeText`}
                render={({ field }) => (
                  <TextField
                    {...field}
                    multiline
                    rows={8}
                    fullWidth
                    defaultValue={initialFieldMap![key]?.freeText}
                    onFocus={() => setFocusedInput(`${key}.freeText`)}
                    placeholder={
                      !field.value
                        ? "Please add your text which you would like to be presented instead of the selected placeholder"
                        : ""
                    }
                    sx={{
                      backgroundColor: colors.white,
                      maxHeight: "220px",
                    }}
                    onClick={(event) => {
                      const target = event.target as HTMLTextAreaElement
                      handleCursorChange(
                        `${key}.freeText`,
                        target.selectionStart,
                      )
                    }}
                    onKeyUp={(event) => {
                      const target = event.target as HTMLTextAreaElement
                      handleCursorChange(
                        `${key}.freeText`,
                        target.selectionStart,
                      )
                    }}
                  />
                )}
              />
            </Box>
          </>
        )
      case "logic":
        return (
          <Box>
            <Box>
              <Box width="100%" sx={{ pb: 0 }}>
                <Typography sx={{ fontSize: 17.5, ml: 1.5, fontWeight: 700 }}>
                  If
                </Typography>
                {conditions[key]?.map((itKey, itIndex) => (
                  <Box
                    key={itKey}
                    sx={{
                      flexDirection: "column",
                      marginBottom: 0.5,
                      ml: 0.5,
                      borderRadius: 1,
                      padding: 1,
                      position: "relative",
                    }}
                  >
                    {conditions[key].length > 1 && (
                      <IconButton
                        sx={{
                          position: "absolute",
                          right: 0,
                          top: 0,
                        }}
                        color="error"
                        onClick={() => {
                          setConditions((prevConditions) => {
                            unregister(`${key}.${itKey}.left`)
                            unregister(`${key}.${itKey}.right`)
                            unregister(`${key}.${itKey}.operator`)
                            unregister(`${key}.${itKey}.logicalOperator`)

                            return {
                              ...prevConditions,
                              [key]: [
                                ...prevConditions[key].slice(0, itIndex),
                                ...prevConditions[key].slice(itIndex + 1),
                              ],
                            }
                          })
                        }}
                      >
                        <RemoveCircle />
                      </IconButton>
                    )}
                    <Box display="flex" flexDirection="row">
                      <Controller
                        control={control}
                        name={`${key}.${itKey}.left`}
                        defaultValue={
                          initialFieldMap![key]?.[itKey]?.left || ""
                        }
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginRight: 1,
                            }}
                          >
                            <FHAutoComplete
                              key={key + "-exp-" + index}
                              fieldKey={key}
                              value={value}
                              model={model}
                              onChange={(event) => {
                                onChange(event)
                              }}
                              error={error}
                            />
                          </Box>
                        )}
                      />
                      <Controller
                        control={control}
                        name={`${key}.${itKey}.operator`}
                        defaultValue={
                          initialFieldMap![key]?.[itKey]?.operator || ""
                        }
                        render={({ field: { value, onChange } }) => (
                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                marginRight: 1,
                              }}
                            >
                              <Select
                                value={value}
                                placeholder="Operator"
                                sx={{
                                  width: "200px",
                                  height: "30px",
                                }}
                                onChange={(event) => {
                                  onChange(event.target.value)
                                }}
                              >
                                <MenuItem value="exists">exists</MenuItem>
                                <MenuItem value="does_not_exist">
                                  does not exist
                                </MenuItem>
                                <MenuItem value="is_equal_to">
                                  is equal to
                                </MenuItem>
                                <MenuItem value="is_less_than">
                                  is less than
                                </MenuItem>
                                <MenuItem value="is_greater_than">
                                  is greater than
                                </MenuItem>
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                marginRight: 1,
                              }}
                            >
                              <Controller
                                control={control}
                                name={`${key}.${itKey}.right`}
                                defaultValue={
                                  initialFieldMap![key]?.[itKey]?.right || ""
                                }
                                render={({ field }) => (
                                  <CustomTextField
                                    {...field}
                                    disabled={
                                      value === "exists" ||
                                      value === "does_not_exist"
                                    }
                                    sx={{
                                      backgroundColor: colors.white,
                                    }}
                                  />
                                )}
                              />
                            </Box>
                          </Box>
                        )}
                      />

                      {conditions[key].length - 1 !== itIndex && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Controller
                            control={control}
                            name={`${key}.${itKey}.logicalOperator`}
                            defaultValue={
                              initialFieldMap![key]?.[itKey]?.logicalOperator ||
                              undefined
                            }
                            render={({ field: { value, onChange } }) => (
                              <Select
                                value={value}
                                sx={{
                                  width: "90px",
                                  height: "30px",
                                  color: colors.primary,
                                  border: value
                                    ? `1px solid ${colors.primary}`
                                    : "",
                                }}
                                onChange={(event) => {
                                  onChange(event.target.value)
                                }}
                              >
                                <MenuItem value="and">AND</MenuItem>
                                <MenuItem value="or">OR</MenuItem>
                              </Select>
                            )}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                ))}
                <Typography sx={{ fontSize: 17.5, ml: 1.5, fontWeight: 700 }}>
                  or
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: 1,
                  }}
                >
                  <Button
                    size="small"
                    startIcon={<Add />}
                    sx={{
                      color: colors.primary,
                      border: `1px solid ${colors.primary}`,
                      backgroundColor: "white",
                      boxShadow: "none",
                      mt: 1.5,
                      ml: 1.5,
                    }}
                    onClick={() => {
                      setConditions((prevConditions) => {
                        const lastElement = last(prevConditions[key]) || 0
                        return {
                          ...prevConditions,
                          [key]: [...prevConditions[key], lastElement + 1],
                        }
                      })
                    }}
                  >
                    Add rule group
                  </Button>
                </Box>
              </Box>
              <Fields
                model={model}
                activeTab={activeTab}
                keys={keys}
                handleClick={(selField) =>
                  handleKeyClick(selField, cursorPositions[`${key}.freeText`])
                }
              />
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <Controller
                  control={control}
                  name={`${key}.true`}
                  defaultValue={initialFieldMap![key]?.true || ""}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      multiline
                      rows={4}
                      onFocus={() => setFocusedInput(`${key}.true`)}
                      label="Show this text if conditions are met"
                      fullWidth
                      value={value}
                      onChange={onChange}
                      sx={{
                        marginTop: "5px",
                        backgroundColor: colors.white,
                        width: "47.5%",
                      }}
                      onClick={(event) => {
                        const target = event.target as HTMLTextAreaElement
                        handleCursorChange(
                          `${key}.freeText`,
                          target.selectionStart,
                        )
                      }}
                      onKeyUp={(event) => {
                        const target = event.target as HTMLTextAreaElement
                        handleCursorChange(
                          `${key}.freeText`,
                          target.selectionStart,
                        )
                      }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={`${key}.false`}
                  defaultValue={initialFieldMap![key]?.false || ""}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      multiline
                      rows={4}
                      onFocus={() => setFocusedInput(`${key}.false`)}
                      label="Show this text if conditions are not met"
                      value={value}
                      fullWidth
                      onChange={onChange}
                      sx={{
                        marginTop: "5px",
                        backgroundColor: colors.white,
                        width: "47.5%",
                      }}
                      onClick={(event) => {
                        const target = event.target as HTMLTextAreaElement
                        handleCursorChange(
                          `${key}.freeText`,
                          target.selectionStart,
                        )
                      }}
                      onKeyUp={(event) => {
                        const target = event.target as HTMLTextAreaElement
                        handleCursorChange(
                          `${key}.freeText`,
                          target.selectionStart,
                        )
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>
        )

      default:
        break
    }
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      {/* {showFields && (
        <Box
          display="flex"
          flexDirection="column"
          overflow="auto"
          maxHeight="70vh"
          padding={1}
          sx={{
            height: "-webkit-fill-available",
          }}
        >
          <Typography paddingRight="16px">FIELDS</Typography>
          {keys.map((_key) => (
            <Button
              key={_key}
              onClick={() => handleKeyClick(_key)}
              sx={{ marginBottom: 1 }}
            >
              {_key}
            </Button>
          ))}
        </Box>
      )} */}
      <Box
        overflow="auto"
        maxHeight="70vh"
        display="flex"
        flexDirection="column"
        flex="1"
        padding={1}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
            width: "80%",
          }}
        >
          {/* <Button
            variant="outlined"
            onClick={() => setShowFields((prev) => !prev)}
            startIcon={showFields ? <KeyboardDoubleArrowLeft /> : undefined}
            endIcon={!showFields ? <KeyboardDoubleArrowRight /> : undefined}
          >
            <Typography paddingRight="10px">FIELDS</Typography>
          </Button> */}
          <Typography paddingRight="16=8px" sx={{ fontSize: 20 }}>
            Placeholders
          </Typography>
          {/* <Button
            variant="outlined"
            onClick={() => setShowPreview((prev) => !prev)}
            startIcon={!showPreview ? <KeyboardDoubleArrowLeft /> : undefined}
            endIcon={showPreview ? <KeyboardDoubleArrowRight /> : undefined}
          >
            <Typography paddingRight="10px">PREVIEW</Typography>
          </Button> */}
        </Box>
        {Object.keys(fieldMap).map((key, index) => {
          const conditionsExist =
            initialFieldMap?.[key]?.[1]?.left &&
            initialFieldMap[key]?.[1]?.operator &&
            initialFieldMap[key]
          return (
            <Accordion
              ref={(el) => {
                if (el) {
                  scrollToRef.current[index] = el
                }
              }}
              key={key}
              expanded={currentExpanded === key}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginBottom: 2,
                border: ` ${
                  currentExpanded === key
                    ? `2px solid ${colors.primary}`
                    : `1.5px solid ${colors.gray7}`
                }`,
                borderRadius: "10px",
                minHeight: currentExpanded === key ? "auto" : "60px",
                overflow: currentExpanded === key ? "visible" : "hidden",
                backgroundColor: "white",
                boxShadow: "none",
              }}
              onChange={(_, expanded) => {
                setActiveTab(conditionsExist ? "logic" : "general")
                setCurrentExpanded(expanded ? key : undefined)
                setTimeout(() => executeScroll(index), 1000)
                if (expanded) {
                  setConditions((prevConditions) => {
                    return {
                      ...prevConditions,
                      [key]: prevConditions?.[key] ? prevConditions[key] : [1],
                    }
                  })
                }
              }}
            >
              <AccordionSummary
                sx={{
                  width: "100%",
                  backgroundColor:
                    currentExpanded === key ? colors.gray4 : colors.white,
                }}
                expandIcon={<ExpandMore />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Box sx={{ width: "20%", fontWeight: 600, pl: 1 }}>{key}</Box>

                {updatesExist(key) && currentExpanded !== key && (
                  <Box sx={{ width: "60%", color: colors.gray3, fontSize: 13 }}>
                    <Box width="100%" textAlign="right">
                      {initialFieldMap![key]?.freeText}
                      {conditionsExist ? "If condition" : ""}
                    </Box>
                  </Box>
                )}
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <FormControlLabel
                  label={activeTab === "logic" ? "Advanced" : "General"}
                  labelPlacement="start"
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingInline: "25px",
                  }}
                  control={
                    <Switch
                      checked={activeTab === "logic"}
                      onChange={(event) =>
                        handleTabChange(
                          event,
                          event.target.checked ? "logic" : "general",
                        )
                      }
                      color="primary"
                    />
                  }
                />
                <Divider sx={{ mt: "-1.1px", mb: 1 }} flexItem />

                <Box
                  sx={{
                    backgroundColor: "white",
                    p: 1,
                    pb: 0,
                  }}
                >
                  <Controller
                    control={control}
                    name={`${key}.type`}
                    render={() => (
                      <>
                        {currentExpanded === key &&
                          renderChildren(activeTab, key, index)}
                        <Box
                          sx={{
                            display: "flex",
                            flex: 1,
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            margin: 1,
                            pr: 0,
                          }}
                        >
                          <Button
                            sx={{ color: "white", mr: 2, height: "30px" }}
                            size="small"
                            variant="outlined"
                            onClick={handleSubmit((data) => {
                              return onApplyConfigurations(data[key], key)
                            })}
                          >
                            {t("Close")}
                          </Button>
                          <Button
                            sx={{ color: "white", height: "30px" }}
                            size="small"
                            color="primary"
                            onClick={handleSubmit((data) => {
                              return onApplyConfigurations(data[key], key)
                            })}
                          >
                            {t("Save")}
                          </Button>
                        </Box>
                      </>
                    )}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </Box>
    </Box>
  )
}
