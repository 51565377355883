import { ExpandMore, Search } from "@mui/icons-material"
import {
  Box,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Switch,
} from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  CustomFormControl,
  CustomInputLabel,
  FilteringWrapper,
  SearchTextField,
  SelectAllDropdown,
  SelectDropdown,
  ToggleWrapper,
} from "./styled"
import { type IInboxFilters } from "../../utils/constants"
import { FilterComponent } from "./components"
import { useQuery } from "@tanstack/react-query"
import { getGlobalInboxAPI } from "../../../../../../services"
import { upperCaseFirstLetter } from "../../../../../../utils"
import { EmailDataTable } from "../EmailDataTable"

type Order = "asc" | "desc"

export const GlobalPanel = () => {
  const { t } = useTranslation()

  const [showMultipleMessages, setShowMultipleMessages] = useState(false)
  const [switchChannel, setSwitchChannel] = useState("")
  const [all, setAll] = useState("")
  const [tablePage, setTablePage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [order, setOrder] = useState<Order>("desc")
  const [orderBy, setOrderBy] = useState<
    keyof IGlobalInboxItemDetail | keyof IGlobalInboxItemEmail
  >("createdAt")
  const [searchKey, setSearchKey] = useState("")
  const [filters, setFilters] = useState<IInboxFilters>({
    source: "",
    startDate: "",
    endDate: "",
    from: "",
    caseNumber: "",
    description: "",
    reasoning: "",
    workflow: "",
  })

  const {
    data: globalInboxData,
    isPending: isGlobalInboxDataPending,
    isRefetching: isGlobalInboxDataRefetching,
    refetch: refetchGlobalInboxData,
  } = useQuery({
    queryKey: ["global-inbox"],
    queryFn: () =>
      getGlobalInboxAPI({
        pageNumber: tablePage + 1,
        pageSize: rowsPerPage,
        orderBy: upperCaseFirstLetter(orderBy),
        order,
        searchTerm: searchKey,
        caseNumber: filters.caseNumber,
        dateFrom: filters.startDate,
        dateTo: filters.endDate,
        channelType: parseInt(switchChannel) ?? undefined,
      }),
  })

  useEffect(() => {
    const handler = setTimeout(
      () => {
        void refetchGlobalInboxData()
      },
      searchKey ? 500 : 150,
    )

    return () => {
      clearTimeout(handler)
    }
  }, [
    searchKey,
    tablePage,
    rowsPerPage,
    order,
    orderBy,
    filters,
    switchChannel,
  ])

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTablePage(0)
    setSearchKey(e.target.value)
  }, [])

  const handleFilterChange = useCallback((filter: IInboxFilters) => {
    setTablePage(0)
    setFilters(filter)
  }, [])

  return (
    <Box display="flex" flexDirection="column" gap="8px">
      <ToggleWrapper>
        <FormControlLabel
          label={t("multipleMessages")}
          control={
            <Switch
              checked={showMultipleMessages}
              onChange={() => setShowMultipleMessages((prev) => !prev)}
            />
          }
        />
        <FilteringWrapper>
          <CustomFormControl>
            <CustomInputLabel>{t("switchChannel")}</CustomInputLabel>
            <SelectDropdown
              value={switchChannel}
              onChange={(e) => {
                setTablePage(0)
                setSwitchChannel(e.target.value as string)
              }}
              label={t("switchChannel")}
              IconComponent={ExpandMore}
            >
              <MenuItem value={undefined}>
                <i>{t("all")}</i>
              </MenuItem>
              <MenuItem value="1">{t("email")}</MenuItem>
              <MenuItem value="2">{t("database")}</MenuItem>
              <MenuItem value="3">{t("interface")}</MenuItem>
            </SelectDropdown>
          </CustomFormControl>
          <CustomFormControl>
            <CustomInputLabel>{t("all")}</CustomInputLabel>
            <SelectAllDropdown
              value={all}
              onChange={(e) => setAll(e.target.value as string)}
              label={t("all")}
              IconComponent={ExpandMore}
            >
              <MenuItem value={undefined}>
                <i>{t("none")}</i>
              </MenuItem>
              <MenuItem value="1">Item 1</MenuItem>
              <MenuItem value="2">Item 2</MenuItem>
            </SelectAllDropdown>
          </CustomFormControl>
          <SearchTextField
            variant="outlined"
            placeholder={t("search")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <FilterComponent
            filters={filters}
            onFilterChange={handleFilterChange}
          />
        </FilteringWrapper>
      </ToggleWrapper>
      <EmailDataTable
        data={globalInboxData}
        isPending={isGlobalInboxDataPending}
        isRefetching={isGlobalInboxDataRefetching}
        page={tablePage}
        rowsPerPage={rowsPerPage}
        order={order}
        orderBy={orderBy}
        updatePage={setTablePage}
        updateRowsPerPage={setRowsPerPage}
        updateOrder={setOrder}
        updateOrderBy={setOrderBy}
        i18nIsDynamicList
        key={1}
      />
    </Box>
  )
}
