import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  createRef,
} from "react"
import { CustomAutocomplete, EmailBodyButton } from "./styled"
import {
  DeleteOutline,
  ExpandMore,
  FilePresent,
  Quiz,
} from "@mui/icons-material"
import {
  Box,
  Button,
  Divider,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  type TextFieldProps,
} from "@mui/material"
import { EmailDetailsTooltip } from "../EmailDetailsTooltip"
import { useTranslation } from "react-i18next"
import { type IDocument } from "@cyntler/react-doc-viewer"
import { useQuery } from "@tanstack/react-query"
import {
  getDocTypesAPI,
  getWorkflowDefinitionsAPI,
} from "../../../../../../../../../../services"
import { AssignDocTypePopup, DeleteAttachmentModal } from "./components"
import { Link } from "react-router-dom"

interface IProps {
  rowDetails: IGlobalInboxItemDetail
  emailDetails: IGlobalInboxItemEmail
  attachments: IGetGlobalInboxAttachmentsResponse
  documents: IDocument[]
  isLoading: boolean
  isEditingDisabled?: boolean
  onUpdateDocuments: (documents: IDocument[]) => void
}

export const EmailBodyAndActions = (props: IProps) => {
  const {
    rowDetails,
    emailDetails,
    attachments,
    documents,
    isLoading,
    isEditingDisabled,
    onUpdateDocuments,
  } = props
  const { t } = useTranslation()

  const [workflowOpen, setWorkflowOpen] = useState<boolean>(false)
  const [showMore, setShowMore] = useState<boolean>(false)
  const [showButton, setShowButton] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    documentUrl: "",
  })

  const boxRef = useRef(createRef<HTMLDivElement>().current)
  const bodyRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const checkContentHeight = () => {
      const bodyElement = bodyRef.current
      if (bodyElement) {
        if (bodyElement.scrollHeight > 190) {
          setShowButton(true)
        } else {
          setShowButton(false)
        }
      }
    }

    checkContentHeight()
  }, [rowDetails.body])

  useEffect(() => {
    const box = boxRef.current

    const handleScroll = () => {
      if (workflowOpen) {
        setWorkflowOpen(false)
      }
    }

    if (box) {
      box.addEventListener("scroll", handleScroll)
    }

    return () => {
      if (box) {
        box.removeEventListener("scroll", handleScroll)
      }
    }
  }, [workflowOpen])

  const { data: workflows, isPending: isWorkflowOptionsPending } = useQuery({
    queryKey: ["workflow-definitions"],
    queryFn: getWorkflowDefinitionsAPI,
  })

  const { data: docTypes, isLoading: isDocTypesLoading } = useQuery<IDocType[]>(
    {
      queryKey: ["global-inbox-doc-types"],
      queryFn: getDocTypesAPI,
    },
  )

  const handleAttachmentPreviewClick = useCallback(
    (doc: IGlobalInboxAttachment) => {
      const { url, name } = doc
      onUpdateDocuments([{ uri: url, fileName: name, fileType: "pdf" }])
    },
    [onUpdateDocuments],
  )

  const handleOpenDeleteModal = useCallback((documentUrl: string) => {
    setDeleteModal({ open: true, documentUrl })
  }, [])

  const handleCloseDeleteModal = useCallback(() => {
    setDeleteModal({ open: false, documentUrl: "" })
  }, [])

  const workflowOptions = useMemo<INameValue[] | undefined>(() => {
    return workflows?.map((definition) => ({
      name: definition.id + ` (${definition.version})`,
      value: definition.id,
    }))
  }, [workflows])

  const disableEditClaim = isEditingDisabled || !emailDetails.claimId

  return (
    <Box
      ref={boxRef}
      display="flex"
      flexDirection="column"
      paddingX="4px"
      overflow="auto"
      width="100%"
      className="contain-overscroll"
    >
      <Typography>{rowDetails.sender}</Typography>
      <Typography variant="small">
        {!emailDetails.caseId && rowDetails.caseNumber
          ? t("possibleCaseNumber")
          : t("caseNumber")}
        :
        <Typography variant="smallBold">
          {" "}
          <Typography variant="smallBold">
            {!emailDetails.caseId && rowDetails.caseNumber
              ? rowDetails.caseNumber ?? "-"
              : emailDetails.caseId ?? "-"}
          </Typography>
        </Typography>
      </Typography>
      <EmailDetailsTooltip
        rowDetails={rowDetails}
        emailDetails={emailDetails}
      />
      <Divider />
      {!rowDetails.subject && !rowDetails.body ? (
        <Typography variant="small" marginTop="16px" marginBottom="14px">
          {t("noContentInEmail")}
        </Typography>
      ) : (
        <>
          <Typography
            variant="regularBold"
            marginTop="16px"
            marginBottom="14px"
          >
            {rowDetails.subject}
          </Typography>
          <Box
            ref={bodyRef}
            margin="8px 0"
            height="auto"
            maxHeight={showMore ? "auto" : "160px"}
            overflow={showMore ? "visible" : "hidden"}
            dangerouslySetInnerHTML={{ __html: rowDetails?.body }}
          />
          {showButton && (
            <EmailBodyButton
              size="small"
              variant="outlined"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? "Show Less" : "Show More"}
            </EmailBodyButton>
          )}
        </>
      )}
      <Divider />
      <Typography variant="regularBold" marginTop="16px">
        {t("attachments")}
      </Typography>
      <Box display="flex" flexDirection="column" gap="8px" margin="8px 0">
        {rowDetails?.attachmentCount === 0 ? (
          <Typography marginLeft="8px" marginTop="8px" variant="small">
            {t("noAttachments")}
          </Typography>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("name")}</TableCell>
                  <TableCell>{t("type")}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading
                  ? Array.from({ length: 2 }).map((_, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell align="right">
                          <Box
                            gap="8px"
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                          >
                            <IconButton disabled size="small">
                              <Quiz fontSize="inherit" />
                            </IconButton>
                            <IconButton disabled size="small">
                              <FilePresent fontSize="inherit" />
                            </IconButton>
                            <Divider flexItem orientation="vertical" />
                            <IconButton disabled color="error" size="small">
                              <DeleteOutline fontSize="inherit" />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  : attachments?.inboxAttachments?.map((d, index) => (
                      <TableRow key={index}>
                        <TableCell>{d.name}</TableCell>
                        <TableCell>{d.docType}</TableCell>
                        <TableCell align="right">
                          <Box
                            gap="8px"
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                          >
                            <AssignDocTypePopup
                              currentDoc={d}
                              folderPath={emailDetails.folderPath}
                              docTypes={docTypes!}
                              isDocTypesLoading={isDocTypesLoading}
                            />
                            <IconButton
                              disabled={
                                isLoading || d.url === documents[0]?.uri
                              }
                              onClick={() => handleAttachmentPreviewClick(d)}
                              size="small"
                            >
                              <FilePresent fontSize="inherit" />
                            </IconButton>
                            <Divider flexItem orientation="vertical" />
                            <IconButton
                              disabled={isEditingDisabled || isLoading}
                              onClick={() => handleOpenDeleteModal(d.url)}
                              color="error"
                              size="small"
                            >
                              <DeleteOutline fontSize="inherit" />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <Typography variant="regularBold" marginTop="16px">
        {t("assignProcessToWorkflow")}
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap="8px"
        margin="8px 0"
      >
        <Box display="flex" alignItems="center" gap="8px" margin="8px 0">
          <CustomAutocomplete
            options={workflowOptions ?? []}
            value={null}
            loading={isWorkflowOptionsPending}
            disabled={isEditingDisabled}
            onChange={(_e, _data) => {}}
            getOptionLabel={(option) =>
              option ? (option as INameValue).name : ""
            }
            popupIcon={<ExpandMore />}
            onOpen={() => setWorkflowOpen(true)}
            onClose={() => setWorkflowOpen(false)}
            open={workflowOpen}
            renderInput={(params) => (
              <TextField
                {...(params as TextFieldProps)}
                placeholder={t("workflow")}
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
              />
            )}
          />
          <Button disabled={isEditingDisabled} size="small">
            {t("workflow")}
          </Button>
        </Box>
        <Link
          to={`/claims/${emailDetails.claimId}`}
          className={disableEditClaim ? "disabled" : ""}
        >
          <Button size="small" disabled={disableEditClaim}>
            {t("editClaim")}
          </Button>
        </Link>
      </Box>
      <DeleteAttachmentModal
        isOpen={deleteModal.open}
        emailId={emailDetails.id}
        documentUrl={deleteModal.documentUrl}
        onClose={handleCloseDeleteModal}
      />
    </Box>
  )
}
