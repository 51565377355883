import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"
import Radio from "@mui/material/Radio"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useQuery } from "@tanstack/react-query"
import { type UseFormReturn, type FieldValues } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { colors } from "../../../../../../utils"
import { getChannelConfigurationsAPI } from "../../../../../../services"
import { Header, Wrapper } from "./styled"
import { Link } from "@mui/icons-material"
import { isArray } from "lodash"

interface IProps {
  handleChange: (
    selectedAction: INodeItem,
    type: "input" | "output" | "selectNext",
    input: string,
    key: string,
    onChange?: (input: any) => void,
  ) => void
  methods: UseFormReturn<FieldValues, any, undefined>
  selectedAction: INodeItem
  setIds: React.Dispatch<React.SetStateAction<Record<string, string>>>
}

const SELECTED_FIELDS = "SelectedFields"
const API_SETUP_ID = "APISetupId"
const API_SETUP_DETAILS_ID = "APISetupDetailsId"
const JSON_RESPONSE_SCHEMA = "JSONResponseSchema"

export const GetDataConfig = (props: IProps) => {
  const { handleChange, methods, selectedAction, setIds } = props
  const { t } = useTranslation()

  const { getValues } = methods

  const [_, setSelectedDetails] = useState<IAPISetupDetails>()

  const [jsonData, setJsonData] = useState<Record<string, any>>({})
  const [selectedFields, setSelectedFields] = useState<Record<string, any>>({})
  const [currentExpanded, setCurrentExpanded] = useState<string>("")
  const [channelConfigurations, setChannelConfigurations] =
    useState<IChannelConfiguration[]>()

  const {
    isPending: isPendingConfigurations,
    isRefetching: isRefetchingConfigurations,
  } = useQuery({
    queryKey: ["channel-configurations"],
    queryFn: async () => {
      const response = await getChannelConfigurationsAPI("Interface")
      setChannelConfigurations(response)

      const controlValues = getValues()

      if (controlValues[JSON_RESPONSE_SCHEMA] !== "null") {
        setJsonData(controlValues[JSON_RESPONSE_SCHEMA])
      }

      setSelectedFields(
        Object.keys(controlValues[SELECTED_FIELDS]).reduce(
          (acc: Record<string, boolean>, key: string) => {
            acc[key] =
              controlValues[SELECTED_FIELDS][key] === "true" ||
              controlValues[SELECTED_FIELDS][key] === true
            return acc
          },
          {},
        ),
      )
    },
    refetchOnMount: true,
  })

  const onSelectConfigurationHandler = (config: IChannelConfiguration) => {
    if (config.apiSetup?.id) {
      handleChange(
        selectedAction,
        "input",
        config.apiSetup?.id,
        API_SETUP_ID,
        (data) => {
          methods.setValue(API_SETUP_ID, data)
        },
      )
    }
  }

  const onSelectDetailsHandler = (details: IAPISetupDetails) => {
    const json = JSON.parse(details.jsonResponseSchema)
    setJsonData(json)
    setSelectedDetails(details)
    handleChange(
      selectedAction,
      "input",
      json,
      JSON_RESPONSE_SCHEMA,
      (data) => {
        setJsonData(data)
        methods.setValue(JSON_RESPONSE_SCHEMA, data)
      },
    )

    const newNodeItem: INodeItem = {
      ...selectedAction,
      inputs: {
        ...selectedAction.inputs,
        [JSON_RESPONSE_SCHEMA]: json,
      },
    }
    handleChange(
      newNodeItem,
      "input",
      details.id,
      API_SETUP_DETAILS_ID,
      (data) => {
        methods.setValue(API_SETUP_DETAILS_ID, data)
      },
    )
  }

  const isLoadingChannelConfigs =
    isPendingConfigurations || isRefetchingConfigurations

  const handleCheckboxChange = (field: string) => {
    setSelectedFields((prev) => {
      const newFieldsData: any = { ...prev, [field]: !prev?.[field] }
      handleChange(
        selectedAction,
        "input",
        newFieldsData,
        SELECTED_FIELDS,
        (data) => {
          methods.setValue(SELECTED_FIELDS, data)
        },
      )
      return newFieldsData
    })
  }

  const numberOfSelectedFields =
    jsonData && selectedFields
      ? `${Object.keys(selectedFields).length}/${Object.keys(jsonData).length}`
      : ""

  const renderFieldSelection = () => (
    <FormGroup sx={{ marginBottom: 5 }}>
      <Grid container spacing={3}>
        {Object.keys(jsonData).map((key) => (
          <Grid item xs={12} sm={4} md={4} key={key}>
            <Card
              sx={{
                height: "85px",
                paddingTop: 0,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                borderRadius: 2,
                border: `1.5px ${selectedFields[key] ? "solid" : "dashed"}  ${
                  colors.primary
                }`,
                backgroundColor: selectedFields[key]
                  ? `${colors.blue6}`
                  : "white",
                boxShadow: "none",
              }}
              onClick={(e) => {
                e.stopPropagation()
                handleCheckboxChange(key)
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  pr: 0,
                }}
              >
                <FormControlLabel
                  onClick={() => handleCheckboxChange(key)}
                  control={
                    <Checkbox
                      sx={{ margin: 0, mr: 2, ml: 3 }}
                      checked={!!selectedFields?.[key]}
                      onChange={() => handleCheckboxChange(key)}
                    />
                  }
                  label={
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography style={{ fontSize: "17.5px" }}>
                        {key}
                      </Typography>
                      <Typography
                        style={{ fontSize: "13px", color: "lightgrey" }}
                      >
                        ({isArray(jsonData[key]) ? "Array" : jsonData[key]})
                      </Typography>
                    </Box>
                  }
                  sx={{
                    width: "100%",
                    textAlign: "left",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    pt: 1,
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </FormGroup>
  )

  return (
    <Box>
      {isLoadingChannelConfigs ? (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Header
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: 5,
              marginnBottom: 2,
            }}
          >
            <Typography variant="largeMedium">Channels</Typography>
          </Header>
          <Wrapper>
            {channelConfigurations?.map(
              (ch, i) =>
                ch.apiSetup?.apiSetupDetails?.map((setApiSetupDetail, j) => (
                  <Accordion
                    sx={{
                      width: "100%",
                      border: `1.5px solid ${colors.gray5}`,
                      boxShadow: "none",
                    }}
                    key={`${i}-${j}`}
                    onClick={() => {
                      setIds({
                        ChannelId: `'${ch.id}'`,
                        ApiSetupId: `'${ch.apiSetup?.id}'`,
                        ApiSetupDetailsId: `'${setApiSetupDetail.id}'`,
                      })
                      onSelectConfigurationHandler(ch)
                      onSelectDetailsHandler(setApiSetupDetail)
                    }}
                  >
                    <AccordionSummary
                      onClick={() => {
                        setCurrentExpanded((prev) => (prev ? "" : `${i}-${j}`))
                      }}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: currentExpanded === `${i}-${j}` ? 2 : 0,
                        backgroundColor:
                          numberOfSelectedFields &&
                          Number(numberOfSelectedFields[0]) !== 0
                            ? colors.blue6
                            : currentExpanded === `${i}-${j}`
                            ? colors.gray6
                            : "white",
                      }}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id={`channel-${i}-setup-${j}`}
                    >
                      <Box
                        sx={{
                          width: "30%",
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "center",
                        }}
                      >
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          label=""
                          checked={currentExpanded === `${i}-${j}`}
                        />
                        <Link
                          style={{
                            fontSize: "50px",
                            color: colors.black,
                            marginRight: 5,
                          }}
                        />
                        <Typography sx={{ fontWeight: 700 }}>
                          {ch.channelName}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "50%",
                          display: "flex",
                          alignItems: "center",
                          fontWeight: 700,
                        }}
                      >
                        Setup: {setApiSetupDetail.method}
                      </Box>
                      {numberOfSelectedFields && (
                        <Box
                          width="20%"
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <Typography sx={{ fontSize: 15, mr: 3 }}>
                            Selected: {numberOfSelectedFields}
                          </Typography>
                        </Box>
                      )}
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ maxHeight: "360px", overflow: "auto" }}
                    >
                      {jsonData && Object.keys(jsonData).length > 0 && (
                        <Box
                          display="flex"
                          flexDirection="column"
                          gap="8px"
                          marginTop="20px"
                        >
                          <Typography
                            style={{
                              marginBottom: "25px",
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                            textAlign="center"
                            variant="regularSemiBold"
                          >
                            {t("selectFields")}:
                          </Typography>
                          {renderFieldSelection()}
                        </Box>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )),
            )}
          </Wrapper>
        </Box>
      )}
    </Box>
  )
}
